<template>
  <div v-if="isOpen" class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <button @click="closeModal" class="close-btn">
          <img :src="closeImg">
        </button>
        <p>نتيجة الامتحان</p>
      </div>

      <div v-if="message" class="text-center p-5">{{ message }}</div>
      <div class="d-flex flex-column result-content" v-else>
        <div class="d-flex">
          <span>الامتحان: </span>
          <p>{{ exam_name }}</p>
        </div>
        <hr>
        <div class="d-flex">
          <span>درجة الامتحان: </span>
          <p>{{ exam_marks }}</p>
        </div>
        <hr>
        <div class="d-flex">
          <span>درجتك: </span>
          <p>{{ student_marks }}</p>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button class="btn-outline-main" @click="openResultDetails">
            عرض الامتحان
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="openResult" class="modal-overlay">
    <div class="modal-content result-modal">
      <div class="modal-header">
        <button @click="closeModal" class="close-btn">
          <img :src="closeImg">
        </button>
        <p> نتيجة الامتحان </p>
      </div>
      <div class="modal-body" >
        <div v-for="(answer, index) in answers" :key="index" class="answer-result">
  <p>{{ index + 1 }}. {{ answer.question }}</p>
  <div class="answers-container">
    <div 
      v-for="(option, optionIndex) in answer.all_answers" 
      :key="optionIndex" 
      :class="{
        'right-answer': answer.correct_answers.includes(option),
        'wrong-answer': answer.student_answer === option && !answer.is_correct
      }"
      class="answer"
    >
      {{ option }}
      <span v-if="answer.correct_answers.includes(option)">
        <img :src="right" alt="Correct">
      </span>
      <span v-else-if="answer.student_answer === option && !answer.is_correct">
        <img :src="wrong" alt="Wrong">
      </span>
    </div>
  </div>
</div>


      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/apiService';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    examId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      closeImg: require('@/assets/images/close-circle.svg'),
      wrong: require('@/assets/images/close-circle-sm.svg'),
      right: require('@/assets/images/tick-circle.svg'),
      exam_name: '',
      exam_marks: '',
      student_marks: '',
      message: '', // To store error message if any
      openResult: false,
      answers:[],
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.getExamResult();
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.openResult = false;
    },
    async getExamResult() {
  try {
    const response = await apiService.getExamResult(this.examId);
    console.log("API Response:", response.data);  // Log the entire response
    
    this.message = '';
    this.exam_name = response.data.exam_name || '';
    this.exam_marks = response.data.exam_marks !== undefined ? response.data.exam_marks : '';
    this.student_marks = response.data.student_marks !== undefined ? response.data.student_marks : '';
    
    // Log the assignment to answers
    this.answers = Array.isArray(response.data.Answers) ? response.data.Answers : [];

    console.log("Answers Array:", this.answers); // Log the answers array
  } catch (error) {
    if (error.response && error.response.status === 403 && error.response.data.message) {
      this.message = error.response.data.message;
    } else {
      this.message = 'Error fetching exam result. Please try again later.';
    }
  }
},
    openResultDetails() {
      this.$emit('close');
      this.openResult = true;
    }
  },
};
</script>



<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
}

.modal-header {
  display: flex;
  justify-content: center;
  background: #FAAF43;
  border-radius: 10px 10px 0 0;
  gap: 27%;
  padding-top: 10px;
  /* padding: 0 10px; */
  padding-right: 10px;
}

.modal-header p {
  color: #FFF;

  font-family: Cairo;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 0;
  position: absolute;
  right: 15px;
}

.close-btn img {
  width: 35px;
  height: 35px;
}

.result-content,.modal-body {
  padding: 20px 30px;
}

.result-content span {
  color: #FAAF43;

  text-align: right;
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 30%;
}

.result-content p{
  color: #000;
  font-family: Cairo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.answer {
  border-radius: 16px;
  background: #F2F2F2;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4E4E4E;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.right-answer {
  border: 2px solid #37B10C;
  background: rgba(55, 177, 12, 0.10);
  color: #37B10C;
}
.wrong-answer {
  border: 2px solid #C50905;
background: rgba(197, 9, 5, 0.10);
color: #C50905;
}
.answers-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.modal-body{
  max-height: 500px;
  overflow-y: auto;
}
.answer-result > p{
  color: #000;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.answer-result{
  margin-bottom: 24px;
}
.result-modal{
  width: 60% !important;
}
</style>