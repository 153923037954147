<template>
    <div class="all-lessons py-4">
      <div class="month-lesson-container" v-for="course in courses" :key="course.id">
        <div class="col-3 ps-3">
            <img class="img lesson-cover" alt="Image" :src="lessonCover" />
        </div>
        <div class="col-9 d-flex flex-column justify-content-between">
                <div class="d-flex align-items-center justify-content-between border-bottom flex-wrap">
                <h3>{{ course.name }}</h3>
                <h6><span class="price-title">سعر الكورس:</span>{{ course.subscription_status === "مجاني" ? "مجاني" : course.price }}</h6>
                </div>
                <div class="pt-2">
                    <p>{{ course.description }}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center flex-wrap">
                    <div class="bagdes-container d-flex gap-2">
                        <span class="video-num-wraper">
                            <img :src="videoSvg" alt="videos"/>
                            <span class="videos-num">{{ course.video_count }} فيديو</span>
                        </span>
                        <span class="exams-num-wraper">
                            <img :src="examSvg" alt="exams"/>
                            <span class="exams-num">{{ course.exam_count }} امتحان</span>
                        </span> 
                        <span class="hws-num-wraper">
                            <img :src="hwSvg" alt="home works"/>
                            <span class="hws-num">{{ course.homework_count }} واجب</span>
                        </span>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                        <div class="text-center">
                <!-- Pass courseId to the openModal method -->
                <button @click="openModal(course.id)"   :disabled="course.subscription_status === 'مجاني' || course.subscription_status === 'مشترك بالفعل'"
                >{{ course.subscription_status }}</button>
        
                <!-- Pass selectedCourseId as a prop to the modal -->
                <PaymentMethodModal :isOpen="isModalOpen" :courseId="selectedCourseId" @close="closeModal" />
                </div>
                <div class="todetails">
                <router-link :to="{ name:'LessonDetails', params:{id: course.id} }">تفاصيل الكورس</router-link>
                </div>
                    </div>
                </div>
        </div>
      </div>
    </div>
  </template>

<script>
import apiService from '@/apiService';
import PaymentMethodModal from './PaymentMethodModal.vue'; 

export default{
    name: 'Lesson',
    components: {
    PaymentMethodModal,
  },
  data() {
    return {
    lessonCover: require('@/assets/images/lesson-cover.png'),
    videoSvg: require('@/assets/images/video-play.svg'),
    examSvg: require('@/assets/images/note.svg'),
    hwSvg: require('@/assets/images/note-add.svg'),
      isModalOpen: false, // Control whether the modal is open or closed
      courses: [], // Store all courses here
      selectedCourseId: null, // Store the selected course id
    };
  },
  mounted() {
    apiService.getCourses()
      .then(response => {
        this.courses = response.data.data;
      })
      .catch(error => {
        console.error("Error fetching courses:", error);
      });
  },
  methods: {
    openModal(courseId) {
      this.selectedCourseId = courseId; // Set the selected course id when opening the modal
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  }
}
</script>

<style scoped>
.all-lessons{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.7;
}
.modal-container {
  text-align: center;
}

.month-lesson-container {
  border-radius: 32px;
  border: 1px solid #FFF;
  background: #FAFAFA;
  position: relative;
margin: 0 100px;
  padding: 20px;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.month-lesson-container h3 {
  color: #000;
  font-family: Cairo;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.month-lesson-container p {
  color: #8C8C8C;
  text-align: right;
  font-family: Cairo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.month-lesson-container h6 {
  color: #FAAF43;
  font-family: Cairo;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.month-lesson-container button {
  border-radius: 10px;
  background: #FAAF43;
  color: #FFF;
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  border: none;
  padding: 8px 20px;
  width: 100%;
}

.price-title{
    color: #A5A5A5;
    font-family: Cairo;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 10px;
}
.rectangle-4 {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  height: 200px;
  background: #f4f4f4;
  border-radius: 16px;
}

.todetails {
  margin: 10px 0;
}
.todetails a{
  font-size: 18px;
  color: #FAAF43;
  border: 1px solid #FAAF43;
  padding: 8px 20px;
  width: 100%;
  border-radius: 8px;
  display: block;
  text-align: center;
  font-weight: 600;
}



.text-wrapper-10 {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.text-wrapper-11 {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.line-2 {
  width: 100%;
  margin: 20px 0;
}

.text-wrapper-12 {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  margin: 20px 0;
}

.rectangle-5 {
  width: 100%;
  height: 50px;
  background: #FAAF43;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-wrapper-13 {
  font-size: 18px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.video-num-wraper{
    background: #4FBDD926;
    color: #4FBCD3;
}
.exams-num-wraper{
    background: #57D12C26;
    color: #37B10C;
}
.hws-num-wraper{
    background: #FAAF4326;
    color: #FAAF43;
}
.bagdes-container > span{
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.lesson-cover{
    border-radius: 5px;
}
@media screen and (max-width: 992px) {
  .month-lesson-container {
    flex-direction: column;
    margin: 0 20px;
  }
  .col-3,.col-9{
    width: 100%;
    text-align: center;
  }
    
}
</style>