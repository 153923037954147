// src/apiService.js
import axios from 'axios';
import store from './store'; // Import the Vuex store

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // Make sure this is the correct base URL for your API
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
  const token = store.state.studentToken;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    console.log('Token attached to request:', token); // Log to verify token attachment
  } else {
    console.warn('No token found in store.');
  }
  return config;
}, error => {
  return Promise.reject(error);
});

apiClient.interceptors.response.use(response => {
  return response;
}, error => {
  console.error('API call error:', error.response ? error.response.data : error.message);
  return Promise.reject(error);
});

export default {
  signUp(data) {
    return apiClient.post('/student/auth/signup', data);
  },
  login(data) {
    return apiClient.post('/student/auth/login', data);
  },
  updateProfile(data) {
    return apiClient.patch('/student/auth/update', data);
  },
  getProfile() {
    return apiClient.get('/student/auth/profile');
  },
  logout() {
    return apiClient.post('/student/auth/logout');
  },
  sendCode(email) {
    return apiClient.post('/student/auth/resetPassword', { email });
  },
  confirmCode(data) {
    return apiClient.post('/student/auth/confirmPinCode', data);
  },
  confirmPassword(data) {
    return apiClient.post('/student/auth/confirmPassword', data);
  },
  getLocations() {
    return apiClient.get('/student/location');
  },
  uploadMedia(image) {
    const formData = new FormData();
    formData.append('image', image);
    return apiClient.post('/api/media', formData);
  },
  uploadMultipleMedia(images) {
    const formData = new FormData();
    images.forEach(image => {
      formData.append('images[]', image);
    });
    return apiClient.post('/api/media/media', formData);
  },
  getAllExams(){
    return apiClient.get(`/student/exams`);
  },
  getAllHw(){
    return apiClient.get(`/student/homeworks`);
  },
  getHomeworkQuestions(homeworkId) {
    return apiClient.get(`/student/homeworks/${homeworkId}`);
  },
  submitHwAnswers(homeworkId, payload) {
    return apiClient.post(`/student/homeworks/${homeworkId}/attempt`, payload);
  },
  getHwResult(homeworkId) {
    return apiClient.get(`/student/homeworks/${homeworkId}/result`);
  },
  getExamQuestions(examId) {
    return apiClient.get(`/student/exams/${examId}`);
  },
  submitExamAnswers(examId, payload) {
    return apiClient.post(`/student/exams/${examId}/attempt`, payload);
  },
  getExamResult(examId) {
    return apiClient.get(`/student/exams/${examId}/result`);
  },
  getCourses(){
    return apiClient.get(`/api/courses`);
  },
  getLessons(courseId){
    return apiClient.get(`/api/lessons/${courseId}`);
  },
  getVideo(lessonId,videoId){
    return apiClient.get(`/api/videos/${lessonId}/${videoId}`);
  },
  getCompletedExams(){
    return apiClient.get(`/student/exams/completed`);
  },
  getCompletedEHws(){
    return apiClient.get(`/student/homeworks/completed`);
  },
  getVideos(lessonId){
    return apiClient.get(`/api/videos/${lessonId}`)
  },
  subscribe(data) {
    return apiClient.post('/student/subscriptions?', data);
  },
  payCode(data) {
    return apiClient.post('/student/codes/markAsUsed', data);
  },
};
