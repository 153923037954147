<template>
  <div class="exam">
     <!-- Show this message if the user is not logged in -->
     <div v-if="!isLoggedIn" class="alert alert-warning alert-login">
      <p>يجب عليك تسجيل الدخول أولاً لعرض الواجب</p>
      <router-link to="/login" class="btn btn-primary">تسجيل الدخول</router-link>
    </div>

    <div v-else class="w-75 m-3 questions-container">
      <div class="watermark">
        <img :src="watermark" alt="watermark">
      </div>
      <!-- Question numbers -->
      <div class="question-numbers" v-if="questions.length > 0">
      <button v-for="(question, index) in questions" :key="index" @click="selectQuestion(index + 1)" :class="{ 'selected-question': selectedQuestion === index + 1 }">
        {{ index + 1 }}
      </button>
    </div>

    <!-- Selected question and answers -->
    <div class="question-content" v-if="selectedQuestion !== null && questions.length > 0 && questions[selectedQuestion - 1]">
      <div class="questions">
        <!-- <h2>Question {{ selectedQuestion }}:</h2> -->
        <div class="question-img-container">
            <img v-if="questions[selectedQuestion - 1].image" :src="questions[selectedQuestion - 1].image.original_url" alt="Question image" class="question-image" />
          </div>
        <p>{{ questions[selectedQuestion - 1].question }}</p>
      </div>
      <div class="answers">
        <label v-for="(answer, index) in questions[selectedQuestion - 1].answers" :key="index" class="gap-3">
         <div>
          <input type="radio" :name="'question' + selectedQuestion" :value="answer.id" v-model="userAnswers[selectedQuestion - 1]">
          {{ answer.answer }}
         </div>
         <div class="ans-img-conatiner">
              <img v-if="answer.image" :src="answer.image.original_url" alt="Answer image" class="answer-image"/>
              <span @click="openFullscreen(answer.image.original_url)" class="full-btn">
                <img :src="fullsreen" alt="">
              </span>
            </div>
        </label>
      </div>
    </div>

    <!-- Submit button -->
    <div v-if="questions.length > 0" class="submit-btn-container">
      <button type="submit" class="orange-btn" @click="submitHw()">إنهاء الواجب</button>
    </div>

    <!-- Exam timer -->
    <div class="exam-time d-flex">
      <img :src="timerImg" alt="">
      <p>باقي من الوقت {{ formatTime(remainingTime) }} </p>
    </div>
    </div>
  </div>

   <!-- Modal -->
   <custom-modal
      :visible="isModalOpen"
      :title="modalTitle"
      :message="modalMessage"
      @close="closeModal"
      @confirm="closeModal"
    />
</template>


<script>
import { mapGetters } from 'vuex';
import apiService from '@/apiService'; // Adjust the path according to your project structure
import CustomModal from './CustomModal.vue';

export default {
  name: 'OpenHw',
  data() {
    return {
      questions: [],
      selectedQuestion: 1,
      userAnswers: {},
      duration: 0, // Duration in minutes
      remainingTime: 0, // Remaining time in seconds
      timer: null, // Timer reference
      hw: null,
      examsign: require('@/assets/images/exam-sign.svg'),
      watermark: require('@/assets/images/watermark.png'),
      timerImg: require('@/assets/images/time.png'),
      fullsreen: require('@/assets/images/full.svg'),
      isModalOpen: false, // For controlling the modal visibility
      modalTitle: '', // To store the modal title dynamically
      modalMessage: '', // To store the modal message dynamically
    };
  },
  components: {
    CustomModal
  },
  computed: {
    ...mapGetters(['isLoggedIn']) // Add the isLoggedIn getter from Vuex store
  },
  mounted() {
    const homeworkId = this.$route.params.id; // Get the hw ID from the route
    if (this.isLoggedIn) {
      this.fetchHwAndQuestions(homeworkId); // Only fetch questions if the user is logged in
    }
  },
  methods: {
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    },
    async fetchHwAndQuestions(homeworkId) {
  try {
    const response = await apiService.getHomeworkQuestions(homeworkId); // Fetch the homework questions
    const hwData = response.data.data; // Adjusted to match the actual response structure
    this.hw = hwData;
    this.questions = hwData.questions || []; // Access the correct path to get questions
    this.duration = hwData.duration || 0; // Store the duration
    this.remainingTime = this.duration * 60; // Convert duration to seconds
    this.startTimer(); // Start the countdown timer
  } catch (error) {
    console.error('Error fetching questions:', error);
  }
},
    selectQuestion(number) {
      this.selectedQuestion = number;
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime -= 1;
        } else {
          this.stopTimer();
          // Handle timer end logic here (e.g., submitting answers)
          this.submitHw(); // Automatically submit when time is up
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    async submitHw() {
  try {
    const payload = {
      answers: {},
    };

    // Collect answers for each question
    Object.keys(this.userAnswers).forEach((questionIndex) => {
          const questionId = this.questions[questionIndex ].id;
          const answer = this.userAnswers[questionIndex];
          payload.answers[questionId] = answer;
        });

    const homeworkId = this.$route.params.id; // Use the correct homework ID
    // Make an API call to submit the answers
    //show result 
    // const response = await apiService.submitHwAnswers(homeworkId, payload);
    // window.alert(`تم إنهاء الواجب بنجاح. النتيجة: ${response.data.marks}`);
   await apiService.submitHwAnswers(homeworkId, payload);
       // Display success modal
       this.modalTitle = 'نجاح';
        this.modalMessage = 'تم إنهاء الواجب بنجاح';
        this.openModal();

        // Redirect after showing the modal
        setTimeout(() => {
          this.$router.push({ name: 'HomeWork' });
        }, 3000); // Delay for 3 seconds to show the modal
  } catch (error) {
    console.error('Error submitting homework:', error);
       // Display failure modal
       this.modalTitle = 'اعادة محاولة';
        this.modalMessage = ' لقد انهيت هذا الواجب من قبل';
        this.openModal();

        // Redirect after showing the modal
        setTimeout(() => {
          this.$router.push({ name: 'HomeWork' });
        }, 3000); // Delay for 3 seconds to show the modal
  }
},
openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openFullscreen(imageUrl) {
    const imgElement = document.createElement('img');
    imgElement.src = imageUrl;
    imgElement.style.width = '50%'; // Adjust this based on your design
    imgElement.style.height = 'auto';

    const fullscreenContainer = document.createElement('div');
    fullscreenContainer.style.position = 'fixed';
    fullscreenContainer.style.top = '0';
    fullscreenContainer.style.left = '0';
    fullscreenContainer.style.width = '100vw';
    fullscreenContainer.style.height = '100vh';
    fullscreenContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    fullscreenContainer.style.display = 'flex';
    fullscreenContainer.style.alignItems = 'center';
    fullscreenContainer.style.justifyContent = 'center';
    fullscreenContainer.style.zIndex = '1000';
    
    // Append the image to the container
    fullscreenContainer.appendChild(imgElement);
    
    // Close the fullscreen when clicking on the image or outside it
    fullscreenContainer.onclick = () => {
      document.body.removeChild(fullscreenContainer);
    };

    document.body.appendChild(fullscreenContainer);
  },
  },
  beforeUnmount() {
    this.stopTimer(); // Clean up the timer when the component is destroyed
  }
};


</script>

<style>
.submit-btn-container ,.exam-time{
  margin:10px  auto;
  width: max-content;
}
.exam {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-numbers {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.question-numbers button {
  margin: 0 5px;
  padding: 5px 17px;
  border-radius: 7px;
  background: #FAFAFA;
  color: #000;
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  border: none;
  margin-bottom: 12px;
}

.question-content {
  text-align: center;
}

.questions,
.exam-time {
  border-radius: 7px;
  background: #FAFAFA;
  padding: 10px 20px !important;
}

.question-content p {
  color: #000;
  text-align: center;
  font-family: Cairo;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.answers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
}

.exam-time p {
  color: #000;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
</style>


