<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  // mounted() {
  //   // Disable right-click context menu
  //   document.addEventListener('contextmenu', this.disableRightClick);

  //   // Disable F12 key for opening Developer Tools
  //   document.addEventListener('keydown', this.disableF12);
  // },
  // beforeUnmount() {
  //   // Remove event listeners when the component is destroyed
  //   document.removeEventListener('contextmenu', this.disableRightClick);
  //   document.removeEventListener('keydown', this.disableF12);
  // },
  // methods: {
  //   disableRightClick(event) {
  //     event.preventDefault();
  //   },
  //   disableF12(event) {
  //     if (event.keyCode === 123) { // F12 key code
  //       event.preventDefault();
  //       event.returnValue = false;
  //     }
  //   }
  // }
};
</script>

<style>
/* Global styles here */
</style>
